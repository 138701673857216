import Vue from 'vue';
import router from '../../router';
import axios from 'axios';
import * as ACTIONS from '../actions';
import * as EVENTS from '../events';

const vue = new Vue();

const state = {
    user: {
        id: null,
        login: null,
        email: null,
        role: null,
        result_access: null,
        client_stat_upload: null,
        telegram: null,
        access: {
            buying: null,
            lg: null,
            offline: null,
            agent: null,
        },
        products: [],
        reg_teamlead: null,
        reg_manager: null,
        operation_stats: null,
        seo_access: 0,
        retention: null,
        reg_manager_teams: [],
        buying_control_reopen: 0,
        two_fa_enabled: 0,
        auditor_teamlead: false,
        teams: null,
        is_buying_pr: false,
        is_buying_seo: false,
        lg_qa: null,
    },
    token: null,
    userEditData: {},
    viewFilters: false,
    userAdd: {
        role: null,
    },
    usersForTeams: [],
    userList: [],
    deleteUserFilter: false,
};

const getters = {
    profile: state => state.user,
    login: state => state.user.login,
    role: state => state.user.role,
    auditor_teamlead: state => state.user.auditor_teamlead,
    token: state => state.token,
    userAddRole: state => state.userAdd.role,
    usersForTeams: state => state.usersForTeams,
    userList: state => state.userList,
    userEditData: state => state.userEditData,
    deleteUserFilter: state => state.deleteUserFilter,
    viewFilters: state => state.viewFilters,
    userProducts: state => state.user.products,
};

const actions = {
    [ACTIONS.USER_FILTER_DELETE_FLAG]: ({commit}, data) => {
        return new Promise((resolve) => {
            commit('setDeleteUserFilter', data);
            resolve();
        });
    },
    [ACTIONS.LOGIN]: ({commit, getters}, data) => {
        return new Promise((resolve) => {
            axios
                .post(getters.api.profile.login, data)
                .then(res => {
                    const token = res.data.data.token;
                    commit('setToken', token);


                    router.push('/');
                    resolve();
                })
                .catch(err => {
                    localStorage.removeItem('user-token');
                    axios.defaults.headers.common['token'] = null;

                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.ADMIN_LOGIN_AS_USER]: ({commit, getters}, login) => {
        return new Promise(() => {
            axios
                .get(getters.api.profile.adminLogin, {params: {login: login}})
                .then(res => {
                    const token = res.data.data.token;

                    commit('setAdminLoginAsUserToken', token);

                    window.location = '/';
                })
                .catch(err => {
                    localStorage.removeItem('user-token');
                    axios.defaults.headers.common['token'] = null;
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.GET_PROFILE]: ({commit, getters, dispatch}) => {
        return new Promise((resolve) => {
            const token = localStorage.getItem('user-token');
            commit('setToken', token);

            return axios
                .get(getters.api.profile.me)
                .then(res => {
                    commit('setProfile', res.data.data);
                    commit('setUserProducts', res.data.data.products);
                    resolve(res.data.data);
                })
                .catch(err => {
                    if (err.response.status === 428) {
                        if (router.currentRoute.path !== '/login') {
                            dispatch(ACTIONS.LOGOUT);
                            return;
                        } else {
                            vue.$eventEmit(EVENTS.SHOW_2FA_DIALOG);
                            return
                        }
                    }

                    if (err.response.data.data.message !== 'Wrong number of segments') {
                        vue.$catchProceed(err);
                    }

                    dispatch(ACTIONS.LOGOUT);
                });
        });
    },
    [ACTIONS.GET_VERSION]: ({getters}) => {
        return new Promise((resolve) => {
            axios.get(getters.api.version.version)
                .then(res => {
                    resolve(parseInt(res.data.version));
                })
                .catch(() => {

                });
        });
    },
    [ACTIONS.LOGOUT]: ({commit, getters}) => {
        return new Promise((resolve) => {

            axios.post(getters.api.profile.logout)
                .then(() => {})
                .catch(() => {});

            commit('setToken', null);
            // отписываемся от событий
            vue.$subscribe(null);

            router.push('/login');
            resolve();
        })
    },
    [ACTIONS.USER_ADD]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .put(getters.api.users.users, data)
                .then(() => {
                    vue.$notifySuccess({text: 'User successfully created'});
                    router.push('/users/list');
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });


    },
    [ACTIONS.USER_ADD_SET_ROLE]: ({commit}, role) => {
        commit('userAddSetRole', role);
    },
    [ACTIONS.USER_LIST]: ({commit, getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.users.users, {params: params})
                .then(res => {
                    commit('setUserList', res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.TEAM_USER_LIST]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.users.teamUsersList)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.USER_LIST_BY_ROLE]: ({getters}, role) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.users.listByRole, {params: {role: role}})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.USER_GET]: ({getters}, userId) => {
        return new Promise((resolve) => {
            axios
                .get(getters.api.users.users + '/' + userId)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });

    },
    [ACTIONS.USER_INFO_GET]: ({getters}, userId) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.users.users + '/' + userId)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });

    },
    [ACTIONS.USER_EDIT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.users.users + '/' + data.userId, data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.PROFILE_EDIT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.users.profile, data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.USER_FILTER_DELETE]: ({getters}, filterId) => {
        return new Promise((resolve) => {
            axios
                .post(getters.api.filters.deleteFilter, {filterId: filterId})
                .then(res => {
                    vue.$notifySuccess({text: 'Filter deleted'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.USER_FILTER_CREATE]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.filters.createFilter, {userId: params.userId, filters: params.filters})
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.USER_FILTER_EDIT]: ({getters}, params) => {
        return new Promise((resolve) => {
            axios
                .post(getters.api.filters.editFilter, {filterId: params.filterId, filter: params.filter})
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.RETURN_TO_ADMIN]: ({commit, dispatch}) => {
        commit('setAdminToken', null);

        dispatch(ACTIONS.GET_PROFILE).then(() => {
            window.location = '/';
        });
    },
    [ACTIONS.TELEGRAM_AUTH]: ({getters}, params) => {
        return new Promise(() => {
            return axios
                .post(getters.api.users.telegram_auth, {hash: params.hash})
                .then(res => {

                    if (res.data.data.status === 'success') {
                        vue.$notifySuccess({title: res.data.data.message});
                    } else {
                        vue.$notifyError({title: res.data.data.message});
                    }
                    router.push('/');
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    router.push('/');
                });
        });
    },
    [ACTIONS.SEND_MESSAGE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.users.notifications, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Sent'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.CREATE_CUSTOM_PRODUCTS]: ({commit}, oflineProduct) => {
        commit('setCustomProduct', oflineProduct);
    },
    [ACTIONS.ADD_RELATED_ACCOUNT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.users.auto_auth + '/' + data.login)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DELETE_RELATED_ACCOUNT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .delete(getters.api.users.auto_auth + '/' + data.login)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.LOGIN_AS_USER]: ({commit, getters}, data) => {
        return new Promise(() => {
            axios
                .get(getters.api.users.user_login + '/' + data.login)
                .then(res => {
                    const token = res.data.data.token;
                    commit('setUserLoginAsUserToken', token);

                    window.location = '/';
                })
                .catch(err => {
                    localStorage.removeItem('user-token');
                    axios.defaults.headers.common['token'] = null;
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.GENERATE_QR_CODE]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.security.qr)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.SEND_APP_CODE]: ({getters, commit}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.security.verify + '/' + data.app_code)
                .then(res => {
                    commit('setToken', res.data.data.token);

                    if (res.data.data.verified === false) {
                        vue.$notifyError({text: 'Wrong code'});
                    } else {
                        vue.$notifySuccess({text: 'Success'});
                    }

                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DISABLED_AUTHENTICATION]: ({getters, commit}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.security.disable + '/' + data.app_code)
                .then(res => {
                    if (res.data.data.disabled === false) {
                        vue.$notifyError({text: 'Wrong code'});
                    } else {
                        vue.$notifySuccess({text: 'Success'});
                    }
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DISABLE_2FA]: ({getters}, user_id) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.security.disable_2fa + '/' + user_id)
                .then(res => {
                    vue.$notifySuccess({text: 'Success'});
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_USER_TOKEN]: ({getters}, role) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.users.token)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
};

const mutations = {
    setUserProducts(state, payload) {
        state.user.products = payload;
    },
    setCustomProduct(state, payload) {
        state.user.products.push(payload);
    },
    setProfile(state, payload) {
        state.user.id = payload.id;
        state.user.login = payload.login;
        state.user.role = payload.role;
        state.user.telegram = payload.telegram;
        state.user.access.buying = payload.buying;
        state.user.access.lg = payload.lg;
        state.user.access.offline = payload.offline;
        state.user.access.agent = payload.agent;
        state.user.result_access = payload.result_access;
        state.user.client_stat_upload = payload.client_stat_upload;
        state.user.reg_teamlead = payload.reg_teamlead;
        state.user.reg_manager = payload.reg_manager;
        state.user.seo_access = payload.seo_access;
        state.user.retention = payload.retention;
        state.user.reg_manager_teams = payload.reg_manager_teams;
        state.user.buying_control_reopen = payload.buying_control_reopen;
        state.user.operation_stats = payload.operation_stats;
        state.user.two_fa_enabled = payload.two_fa_enabled;
        state.user.auditor_teamlead = payload.auditor_teamlead;
        state.user.team_products = payload.team_products;
        state.user.teams = payload.teams;
        state.user.products = payload.products;
        state.user.is_buying_pr = payload.is_buying_pr;
        state.user.is_buying_seo = payload.is_buying_seo;
        state.user.lg_qa = payload.lg_qa;

        Vue.prototype.$user.set({
            id: payload.id,
            role: payload.role,
            telegram: payload.telegram,
            name: payload.name,
            login: payload.login,
            buying: payload.buying,
            offline: payload.offline,
            lg: payload.lg,
            result_access: payload.result_access,
            client_stat_upload: payload.client_stat_upload,
            reg_manager: payload.reg_manager,
            reg_teamlead: payload.reg_teamlead,
            seo_access: payload.seo_access,
            retention: payload.retention,
            reg_manager_teams: payload.reg_manager_teams,
            buying_control_reopen: payload.buying_control_reopen,
            operation_stats: payload.operation_stats,
            agent: payload.agent,
            two_fa_enabled: payload.two_fa_enabled,
            auditor_teamlead: payload.auditor_teamlead,
            team_products: payload.team_products,
            teams: payload.teams,
            products: payload.products,
            lg_qa: payload.lg_qa,

            is_buying_seo: payload.is_buying_seo,
            is_buying_pr: payload.is_buying_pr,
            access: {
                buying: payload.buying,
                lg: payload.lg,
                offline: payload.offline,
                agent: payload.agent,
            }
        });
    },
    userAddSetRole(state, payload) {
        state.userAdd.role = payload;
    },
    setUsersForTeams(state, payload) {
        state.usersForTeams = payload;
    },
    setUserList(state, payload) {
        state.userList = payload;
    },
    setUserEditData(state, payload) {
        state.userEditData = payload;
    },
    setDeleteUserFilter(state, payload) {
        state.deleteUserFilter = payload;
    },
    setViewFilters(state, payload) {
        state.viewFilters = payload;
    },
    setToken(state, payload) {
        state.token = payload;

        if (payload === null) {
            localStorage.removeItem('user-token');
            localStorage.removeItem('admin-token');

            state.user.id = null;
            state.user.login = null;
            state.user.role = null;
            state.user.access.buying = null;
            state.user.access.lg = null;

            Vue.prototype.$user.set({id: null, role: 'guest', name: null, login: null, buying: null, lg: null});
        } else {
            localStorage.setItem('user-token', payload);
        }
        axios.defaults.headers.common['token'] = payload;
    },
    setAdminLoginAsUserToken(state, payload) {
        state.token = payload;

        // сохраняем токен админа для возврата в него
        const admin_token = localStorage.getItem('user-token');

        localStorage.setItem('admin-token', admin_token);
        localStorage.setItem('user-token', payload);


        axios.defaults.headers.common['token'] = payload;
    },
    setUserLoginAsUserToken(state, payload) {
        localStorage.setItem('user-token', payload);
        axios.defaults.headers.common['token'] = payload;
    },
    setAdminToken(state, payload) {
        state.token = payload;

        // возвращаем токен админа
        const admin_token = localStorage.getItem('admin-token');
        localStorage.setItem('user-token', admin_token);

        localStorage.removeItem('admin-token');

        axios.defaults.headers.common['token'] = payload;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}
