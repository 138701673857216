import Vue from 'vue';
import Router from 'vue-router';
import axios from 'axios';
import store from './../store';
import access from './../plugins/router-access';
import router_custom from '../router';

const VueCookie = require('vue-cookie');
const vue = new Vue();

Vue.use(Router);

const router = new Router({
    // mode: 'history',
    routes: [
        {
            path: '/notifications',
            name: 'Notifications',
            component: () => import('@/components/notifications/Notifications.vue'),
            meta: {
                title: 'Notifications',
                requiresAuth: true,
            },
        },
        {
            path: '/chats',
            name: 'Chats',
            component: () => import('@/components/user_chats/ChatList.vue'),
            meta: {
                title: 'Chats',
                requiresAuth: true,
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/components/pages/Login.vue'),
            alias: 'login',
            meta: {
                requiresAuth: false,
                name: 'login',
                title: 'Login page',
            },
        },
        {
            path: '/',
            name: 'Main Page',
            component: () => import ('@/components/pages/MainPage.vue'),
            meta: {
                title: 'Main Page',
                requiresAuth: true,
            },
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: () => import('@/components/lg/Dashboard.vue'),
            meta: {
                title: 'Dashboard',
                requiresAuth: true,
            },
        },
        {
            path: '/manual',
            name: 'Manual',
            component: () => import('@/components/pages/Manual.vue'),
            meta: {
                requiresAuth: true,
                title: 'Manual',
            },
        },

        {
            path: '/leads/list',
            name: 'Lead List',
            component: () => import('@/components/lg/leads/List.vue'),
            meta: {
                requiresAuth: true,
                title: 'Lead list',
                permissions: access.leadList(),
                access: {
                    lg: true,
                    buying: false,
                }
            },
        },
        {
            path: '/leads/add',
            name: 'Add new lead',
            component: () => import('@/components/lg/leads/Add.vue'),
            alias: 'site_add',
            meta: {
                requiresAuth: true,
                title: 'Lead add',
                access: {
                    lg: true,
                    buying: false,
                }
            },
        },
        {
            path: '/leads/card/:id',
            name: 'Lead Card',
            component: () => import('@/components/lg/leads/Card.vue'),
            meta: {
                requiresAuth: true,
                name: 'Lead Card',
                title: 'Lead Card',
                access: {
                    lg: true,
                    buying: false,
                }
            },
        },
        {
            path: '/control/list',
            name: 'Control leads list',
            component: () => import('@/components/lg/control/List.vue'),
            alias: 'control',
            meta: {
                requiresAuth: true,
                name: 'Checking leads',
                title: 'Checking leads',
                access: {
                    lg: true,
                    buying: false,
                }
            },
        },

        {
            path: '/users/add',
            name: 'Add new user',
            component: () => import('@/components/service/users/Add.vue'),
            alias: 'user_add',
            meta: {
                requiresAuth: true,
                name: 'Add new user',
                title: 'User Add',
                permissions: access.admins(),
            },
        },
        {
            path: '/users/list',
            name: 'Users List',
            component: () => import('@/components/service/users/List.vue'),
            alias: 'user_list',
            meta: {
                requiresAuth: true,
                name: 'Users List',
                title: 'User List',
                permissions: access.admins(),
            },
        },
        {
            path: '/users/card/:id',
            name: 'User card',
            component: () => import('@/components/service/users/Card.vue'),
            meta: {
                requiresAuth: true,
                name: 'user card',
                title: 'User card',
            },
        },

        {
            path: '/geoGroups/list',
            name: 'Geo groups',
            component: () => import('@/components/service/geoGroups/List.vue'),
            alias: 'geo_group',
            meta: {
                requiresAuth: true,
                name: 'Geo groups',
                title: 'Geo groups',
                permissions: access.admins(),
            },
        },

        {
            path: '/teams/list',
            name: 'Team list',
            component: () => import('@/components/service/teams/List.vue'),
            alias: 'team_list',
            meta: {
                requiresAuth: true,
                name: 'Team list',
                title: 'Teams list',
                permissions: access.admins(),
            },
        },
        {
            path: '/teams/card/:id',
            name: 'Team card',
            component: () => import('@/components/service/teams/Card.vue'),
            meta: {
                requiresAuth: true,
                name: 'team card',
                title: 'Team card',
                permissions: access.admins(),
            },
        },

        {
            path: '/tags/list',
            name: 'Tags list',
            component: () => import('@/components/service/tags/List.vue'),
            alias: 'tag_list',
            meta: {
                requiresAuth: true,
                name: 'Tags list',
                title: 'Tags list',
                permissions: access.admins(),
            },
        },

        // begin partners route
        {
            path: '/partners/my/partners',
            name: 'My partners',
            component: () => import ('@/components/buying/partners/my_partners/List.vue'),
            meta: {
                requiresAuth: true,
                title: 'My partners',
                access: {
                    lg: false,
                    buying: true,
                }
            },
        },
        {
            path: '/partners/list',
            name: 'Partners list',
            component: () => import ('@/components/buying/partners/List.vue'),
            meta: {
                requiresAuth: true,
                title: 'Partners',
                access: {
                    lg: false,
                    buying: true,
                }
            },
        },
        {
            path: '/partners/add',
            name: 'Add new partner',
            component: () => import ('@/components/buying/partners/Add.vue'),
            meta: {
                requiresAuth: true,
                name: 'Add new partner',
                title: 'Add partner',
                access: {
                    lg: false,
                    buying: true,
                }
            },
        },
        {
            path: '/partners/card/:id',
            name: 'Partners card',
            component: () => import ('@/components/buying/partners/Card.vue'),
            meta: {
                requiresAuth: true,
                name: 'Partners card',
                title: 'Partners card',
                access: {
                    lg: false,
                    buying: true,
                }
            },
        },
        {
            path: '/partners/card/offline/:id',
            name: 'Partners card offline',
            component: () => import ('@/components/buying/partners/CardOffline.vue'),
            meta: {
                requiresAuth: true,
                name: 'Partners card offline',
                title: 'Partners card offline',
                access: {
                    lg: false,
                    buying: true,
                }
            },
        },
        {
            path: '/partners/groups',
            name: 'Partners groups',
            component: () => import ('@/components/buying/partners/_groups/List.vue'),
            meta: {
                requiresAuth: true,
                title: 'Partners groups',
                access: {
                    lg: false,
                    buying: true,
                }
            },
        },
        {
            path: '/partners/groups/add',
            name: 'Add groups',
            component: () => import ('@/components/buying/partners/_groups/Add.vue'),
            meta: {
                requiresAuth: true,
                name: 'Add groups',
                title: 'Add groups',
                access: {
                    lg: false,
                    buying: true,
                }
            },
        },
        {
            path: '/groups/card/:id',
            name: 'Group card',
            component: () => import('@/components/buying/partners/_groups/Card.vue'),
            meta: {
                requiresAuth: true,
                name: 'Group card',
                title: 'Group card',
                access: {
                    lg: false,
                    buying: true,
                }
            },
        },

        // deals route
        {
            path: '/deals/add/:type/:id',
            name: 'Add new deals',
            component: () => import ('@/components/buying/deals/Add.vue'),
            meta: {
                requiresAuth: true,
                title: 'New deals',
                access: {
                    lg: false,
                    buying: true,
                }
            },
        },
        {
            path: '/deals/card/:type/:id',
            name: 'Card Deals',
            component: () => import ('@/components/buying/deals/Card.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                title: 'Card Deals',
                access: {
                    lg: false,
                    buying: true,
                }
            },
        },
        {
            path: '/deals/list',
            name: 'Deals List',
            component: () => import ('@/components/buying/deals/List.vue'),
            meta: {
                requiresAuth: true,
                title: 'Deals List',
                access: {
                    lg: false,
                    buying: true,
                }
            },
        },

        // finance
        {
            path: '/finance/list',
            name: 'Finance',
            component: () => import ('@/components/buying/finance/List.vue'),
            // component: List,
            meta: {
                requiresAuth: true,
                title: 'Finance',
                access: {
                    lg: false,
                    buying: true,
                }
            },
        },
        {
            path: '*',
            component: () => import('@/components/pages/NotFound.vue')
            // component: NotFound
        },

        //payments
        {
            path: '/payments/list',
            name: 'Payments List',
            component: () => import ('@/components/buying/payments/List.vue'),
            meta: {
                requiresAuth: true,
                title: 'Payments List',
                permissions: access.admins(),
                access: {
                    lg: false,
                    buying: true,
                }
            },
        },

        {
            path: '/black/list',
            name: 'Black list',
            component: () => import ('@/components/service/blackList/List.vue'),
            meta: {
                requiresAuth: true,
                title: 'Black list',
                access: {
                    lg: false,
                    buying: true,
                }
            },
        },

        // report
        {
            path: '/general_report',
            name: 'General report',
            component: () => import('@/components/buying/report/Report.vue'),
            meta: {
                title: 'General report',
                requiresAuth: true,
            },
        },

        {
            path: '/report',
            name: 'Report',
            component: () => import('@/components/buying/extension_report/Report.vue'),
            meta: {
                title: 'Report',
                requiresAuth: true,
            },
        },

        // icons
        {
            path: '/icons',
            name: 'Icons',
            component: () => import('@/components/service/icons/List.vue'),
            meta: {
                title: 'Icons',
                requiresAuth: true,
                permissions: access.adminOnly(),
            },
        },

        {
            path: '/profile',
            name: 'Profile',
            component: () => import('@/components/service/users/Profile.vue'),
            meta: {
                requiresAuth: true,
                name: 'Profile',
                title: 'Profile',
            },
        },

        {
            path: '/telegram_auth/:hash',
            name: 'Telegram auth',
            component: () => import('@/components/pages/TelegramAuth.vue'),
            meta: {
                requiresAuth: true,
                name: 'Telegram auth',
                title: 'Telegram auth',
            },
        },

        {
            path: '/logs',
            name: 'Logs',
            component: () => import('@/components/service/logs/Logs.vue'),
            meta: {
                requiresAuth: true,
                name: 'Logs',
                title: 'Logs',
            },
        },

        {
            path: '/smm/titles',
            name: 'Titles',
            component: () => import('@/components/notifications/tickets_smm/SmmTitles.vue'),
            meta: {
                requiresAuth: true,
                name: 'Titles',
                title: 'Titles',
            },
        },

        {
            path: '/tickets',
            name: 'Tickets',
            component: () => import('@/components/notifications/tickets_cpm/TicketsList.vue'),
            meta: {
                requiresAuth: true,
                name: 'Tickets',
                title: 'Tickets',
            },
        },

        {
            path: '/tickets/dashboard',
            name: 'Tickets dashboard',
            component: () => import('@/components/notifications/tickets_cpm/dashboard/Dashboard.vue'),
            meta: {
                requiresAuth: true,
                name: 'Tickets dashboard',
                title: 'Tickets dashboard',
            },
        },

        {
            path: '/seo/keywords',
            name: 'SEO keywords',
            component: () => import('@/components/seo/Dashboard.vue'),
            meta: {
                requiresAuth: true,
                name: 'SEO keywords',
                title: 'SEO keywords',
            },
        },

        {
            path: '/seo/domains',
            name: 'SEO domains',
            component: () => import('@/components/seo/Domains.vue'),
            meta: {
                requiresAuth: true,
                name: 'SEO domains',
                title: 'SEO domains',
            },
        },

        {
            path: '/brandstealers',
            name: 'Brandstealers',
            component: () => import('@/components/brandstealers/List.vue'),
            meta: {
                requiresAuth: true,
                name: 'Brandstealers',
                title: 'Brandstealers',
            },
        },

        {
            path: '/lg/report',
            name: 'Lg report',
            component: () => import('@/components/lg/report/LgReport.vue'),
            meta: {
                requiresAuth: true,
                name: 'Lg report',
                title: 'Lg report',
            },
        },
        {
            path: '/partner/tickets',
            name: 'Partner tickets',
            component: () => import('@/components/notifications/tickets_partner/PartnerTickets.vue'),
            meta: {
                requiresAuth: true,
                name: 'Partner tickets',
                title: 'Partner tickets',
            },
        },
        {
            path: '/articles',
            name: 'Articles',
            component: () => import('@/components/articles/List.vue'),
            meta: {
                requiresAuth: true,
                name: 'Articles',
                title: 'Articles'
            },
        },
        {
            path: '/articles/card/:id',
            name: 'Article card',
            component: () => import ('@/components/articles/Card.vue'),
            meta: {
                requiresAuth: true,
                name: 'Article',
                title: 'Article'
            },
        },
        {
            path: '/articles/create',
            name: 'Create article',
            component: () => import ('@/components/articles/Editor.vue'),
            meta: {
                requiresAuth: true,
                name: 'Create article',
                title: 'Create article',
                permissions: access.adminOnly(),
            },
        },
        {
            path: '/articles/update/:id',
            name: 'Update article',
            component: () => import ('@/components/articles/Editor.vue'),
            meta: {
                requiresAuth: true,
                name: 'Update article',
                title: 'Update article',
                permissions: access.adminOnly(),
            },
        },
        {
            path: '/wallet/tips',
            name: 'Wallet tips',
            component: () => import('@/components/service/wallet_tips/List.vue'),
            meta: {
                requiresAuth: true,
                name: 'Wallet tips',
                title: 'Wallet tips',
            },
        },
        {
            path: '/reminders',
            name: 'Reminders',
            component: () => import('@/components/notifications/reminders/Reminders.vue'),
            meta: {
                requiresAuth: true,
                name: 'Reminders',
                title: 'Reminders',
            },
        },

        {
            path: '/tickets/affiliate',
            name: 'Tickets Affiliate',
            component: () => import('@/components/buying/affiliate_tickets/List.vue'),
            meta: {
                requiresAuth: true,
                name: 'Tickets affiliate',
                title: 'Tickets affiliate',
            },
        },

        {
            path: '/tickets/affiliate/card/:id',
            name: 'Ticket card',
            component: () => import ('@/components/buying/affiliate_tickets/card/Card.vue'),
            meta: {
                requiresAuth: true,
                name: 'Ticket card',
                title: 'Ticket card'
            },
        },

        {
            path: '/parser',
            name: 'A-parser',
            component: () => import('@/components/seo/AParser.vue'),
            meta: {
                requiresAuth: true,
                name: 'A-parser',
                title: 'A-parser',
            },
        },

        {
            path: '/conditions/list',
            name: 'Agents conditions',
            component: () => import ('@/components/mobicash/conditions/List.vue'),
            meta: {
                requiresAuth: true,
                title: 'Agents conditions',
            },
        },

        {
            path: '/agents/list',
            name: 'Agents list',
            component: () => import ('@/components/mobicash/agentsList/List.vue'),
            meta: {
                requiresAuth: true,
                title: 'Agents list',
                access: {
                    agent: true,
                }
            },
        },

        {
            path: '/documentation_new',
            name: 'Documentation',
            component: () => import ('@/components/buying/documentation_new/List.vue'),
            meta: {
                requiresAuth: true,
                title: 'Documentation',
            },
        },
        {
            path: '/documentation/report',
            name: 'Documentation report',
            component: () => import ('@/components/buying/documentation_report/Report.vue'),
            meta: {
                requiresAuth: true,
                title: 'Documentation report',
                access: {
                    lg: false,
                    buying: true,
                }
            },
        },

        {
            path: '/affiliate/report',
            name: 'Affiliate report',
            component: () => import ('@/components/buying/partners_affiliate_report/Report'),
            meta: {
                requiresAuth: true,
                title: 'Affiliate report',
            },
        },

        {
            path: '/general/settings',
            name: 'General settings',
            component: () => import ('@/components/service/general_settings/GeneralSettings.vue'),
            meta: {
                requiresAuth: true,
                title: 'General settings',
            },
        },

    ]

});

import * as ACTIONS from './../store/actions';
import * as EVENTS from "./../store/events";

router.beforeEach((to, from, next) => {

    document.title = to.meta.title;
    const token = localStorage.getItem('user-token');

    // для страниц, требующих авторизацию, проверяем, авторизован ли юзер
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!token) {
            return next({path: '/login'});
        }
    }

    // авторизованных на страницу логина не пускаем
    if (to.meta.name === 'login' && token) {
        return next({path: '/'});
    }

    store.dispatch(ACTIONS.GET_PROFILE).then(res => {
        // перевіряємо чи є доступ користувача до розділів lg and buying

        if (res.role !== 'admin') {
            if (to.matched.some(record => record.meta.access)) {

                if (to.meta.access.buying === true && res.buying === false) {
                    return next({path: '/'});
                }

                if (to.meta.access.lg === true && res.lg === false) {
                    return next({path: '/'});
                }
            }
        }

        // заставляем после перехода заполнить телеграм
        const allowPaths = ['/profile', '/login', '/logout', '/articles/card/2'];
        const paths = to.path;
        const currentDate = new Date().toISOString().slice(0, 10)

        if(allowPaths.indexOf(paths) && !store.getters.profile.telegram) {
            vue.$notifyWarning({title: 'Add your Telegram in profile'});
            window.location = '/#/profile';
        }

        if(allowPaths.indexOf(paths) === -1 && !store.getters.profile.two_fa_enabled) {
            vue.$notifyWarning({title: 'Add 2fa authentication in profile'});
            return router_custom.push('/profile');
        }
    })

    if (token) {
        store.dispatch(ACTIONS.GET_VERSION).then(fileVersion => {

            let cookieVersion = parseInt(VueCookie.get('version'));

            if (cookieVersion) { // если версия есть - сравниваем с текущей

                // если различаются - обновляем куку и перезагружаем страницу
                if (cookieVersion !== fileVersion) {
                    updateVersion(fileVersion);
                } else {
                    axios.defaults.headers.common['path'] = to.path;
                    next();
                }

            } else { // если версии нет - добавляем куку с версией и перезагружаем
                updateVersion(fileVersion);
            }
        });
    } else {
        next();
    }

    const query = to.query;
    for (let key in query) {
        if (query.hasOwnProperty(key)) {

            switch (key) {

                case 'nid':

                    store.dispatch(ACTIONS.READ_NOTIFICATION, {
                        notification_id: query[key],
                    }).then(() => {
                        vue.$eventEmit(EVENTS.GET_NOTIFICATION);
                    });

                    break;

                case 'groupByValue':
                    this.dashboard.filter_group_by_value = query[key];
                    break;

            }
        }
    }

});

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}
const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace (location) {
    return originalReplace.call(this, location).catch(err => err)
}

function updateVersion(fileVersion) {
    VueCookie.set('version', fileVersion, 1);
    location.reload();

    // ставим куку для создания уведомления об обновлении
    VueCookie.set('crmUpdate', true, 1);
}

export default router;
