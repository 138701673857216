import axios from 'axios';
import * as ACTIONS from '../actions';
import Vue from 'vue';

const vue = new Vue();

const state = {
    teamList: [],
    teamEditAccessCounter: 0,
    filters: {
        countryList: [],
        geoGroupList: [],
        SourceTypeList: [],
        sourceCategoryList: [],
    },
};

const getters = {
    teamList: state => state.teamList,
    teamEditAccessCounter: state => state.teamEditAccessCounter,
    teamFiltersCountryList: state => state.filters.countryList,
    teamFiltersGeoGroupList: state => state.filters.geoGroupList,
    teamFiltersSourceTypeList: state => state.filters.SourceTypeList,
    teamFiltersSourceCategoryList: state => state.filters.sourceCategoryList,
};

const actions = {
    [ACTIONS.TEAM_LIST]: ({commit, getters}) => {

        return new Promise((resolve) => {
            return axios
                .get(getters.api.teams.teams)
                .then(res => {
                    commit('setTeamList', res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });

    },
    [ACTIONS.TEAM_GET]: ({getters}, teamId) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.teams.teams + '/' + teamId, {params: {users: true}})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.TEAM_CREATE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.teams.teams, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Team created'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.TEAM_EDIT]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.teams.teams + '/' + data.teamId + '?users=true', data)
                .then(res => {
                    // vue.$notifySuccess({text: 'Team edited'});
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.TEAM_NAME_UNIQUE_CHECK]: ({getters}, params) => {
        return new Promise((resolve, reject) => {
            var url = getters.api.teams.checkName + '/' + params.name;
            if (params.teamId) {
                url = url + '/' + params.teamId;
            }
            return axios
                .get(url)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.TEAM_USER_GET_ACCESS]: ({getters}, params) => {

        return new Promise((resolve) => {
            return axios
                .get(getters.api.teams.getAccess, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.TEAM_USER_IDS_GET]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.teams.getTeamUserIds, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.TEAM_USER_SAVE_ACCESS]: ({dispatch, getters}, data) => {

        return new Promise((resolve) => {
            return axios
                .post(getters.api.teams.saveAccess + '/' + data.teamId + '/' + data.userId + '/' + data.teamUserId, data)
                .then(res => {
                    dispatch(ACTIONS.SET_TEAM_EDIT_ACCESS_COUNTER, getters.teamEditAccessCounter + 1);
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.SET_TEAM_EDIT_ACCESS_COUNTER]: ({commit}, count) => {
        commit('setTeamEditAccessCounter', count);
    },
    [ACTIONS.TEAM_ACCESS_FILTERS_COUNTRY]: ({commit}, count) => {
        commit('setTeamEditAccessCounter', count);
    },

    [ACTIONS.TEAM_ACCESS_FILTERS_COUNTRY]: ({commit, getters, dispatch}) => {
        return new Promise((resolve) => {
            axios
                .get(getters.api.teams.filters.getCountryList)
                .then(res => {
                    commit('setTeamCountryFilterList', res.data.data);
                    resolve();
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    dispatch(ACTIONS.LOGOUT);
                });
        });
    },

    [ACTIONS.TEAM_ACCESS_FILTERS_GEO_GROUP]: ({commit, getters, dispatch}) => {
        return new Promise((resolve) => {
            axios
                .get(getters.api.teams.filters.getGeoGroupList)
                .then(res => {
                    commit('setTeamGeoGroupFilterList', res.data.data);
                    resolve();
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    dispatch(ACTIONS.LOGOUT);
                });
        });
    },

    [ACTIONS.TEAM_ACCESS_FILTERS_SOURCE_TYPE]: ({commit, getters, dispatch}) => {
        return new Promise((resolve) => {
            axios
                .get(getters.api.teams.filters.getSourceTypeList)
                .then(res => {
                    commit('setTeamSourceTypeFilterList', res.data.data);
                    resolve();
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    dispatch(ACTIONS.LOGOUT);
                });
        });
    },

    [ACTIONS.TEAM_ACCESS_FILTERS_SOURCE_CATEGORY]: ({commit, getters, dispatch}) => {
        return new Promise((resolve) => {
            axios
                .get(getters.api.teams.filters.getSourceCategoryList)
                .then(res => {
                    commit('setTeamSourceCategoryFilterList', res.data.data);
                    resolve();
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    dispatch(ACTIONS.LOGOUT);
                });
        });
    },
};

const mutations = {
    setTeamList(state, payload) {
        state.teamList = payload;
    },
    setTeamCountryFilterList(state, payload) {
        state.filters.countryList = payload;
    },
    setTeamGeoGroupFilterList(state, payload) {
        state.filters.geoGroupList = payload;
    },
    setTeamSourceTypeFilterList(state, payload) {
        state.filters.SourceTypeList = payload;
    },
    setTeamSourceCategoryFilterList(state, payload) {
        state.filters.sourceCategoryList = payload;
    },
    setTeamEditAccessCounter(state, payload) {
        state.teamEditAccessCounter = payload;
    }

};

export default {
    state,
    getters,
    actions,
    mutations,
}
