import axios from 'axios';
import * as ACTIONS from '../actions';
import Vue from 'vue';

const vue = new Vue();

const state = {
    deals: {
        list: [],
        placeTypes: [],
        count: 0,
        loader: true,
        statusList: [],
        departmentList: []
    },
    dealPlaces: [],
    validationDeal: false,
    dealFormIsValid: false,
    dealPlacesCurency: null,
    addDealAnalytics: [
        {
            comment: null,
            devicesScreenshot: null,
            link: null,
            analytics: [],
            totalCost: null,
            totalTraffic: null,
            traficGeoScreenshot: null,
            traficScreenshot: null,
        }
    ],
    invoiceCount: 0,
    dealFormValidation: false,
    dealAnalyticsValidation: false,
    reviewValidation: false,
    buyingCpmStats: {},
    macrosList: [],
    redirectorList: [],

    placementCost: {},
    paymentsCost: {}
};

const getters = {
    placementCost: state => state.placementCost,
    paymentsCost: state => state.paymentsCost,

    dealsList: state => state.deals.list,
    placeTypes: state => state.deals.placeTypes,
    dealsCount: state => state.deals.count,
    dealsLoader: state => state.deals.loader,
    dealPlaces: state => state.dealPlaces,
    validationDeal: state => state.dealFormIsValid,
    dealStatusList: state => state.deals.statusList,
    departmentList: state => state.deals.departmentList,
    dealPlacesCurency: state => state.dealPlacesCurency,
    addDealAnalytics: state => state.addDealAnalytics,
    invoiceCount: state => state.invoiceCount,
    dealAnalyticsValidation: state => state.dealAnalyticsValidation,
    reviewValidation: state => state.dealFormIsValid && state.dealAnalyticsValidation,
    macrosList: state => state.macrosList,
    redirectorList: state => state.redirectorList,
};

const actions = {
    [ACTIONS.MASS_ADD_DEAL_PLACES]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.deals.massDealPlaces + '/' + data.deal_id, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DEAL_CREATE]: ({getters, dispatch}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .put(getters.api.deals.deals, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Deals create'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.DEAL_UPDATE]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.deals.deals + '/' + data.deal_id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Deals update'});
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.response);
                    vue.$catchProceed(err);

                });
        });
    },
    [ACTIONS.DEAL_INFO]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.deals + '/' + params.deal_id, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.CHECK_AVAILABLE_PAYMENT]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.available_payment + '/' + params.deal_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DEALS_LIST]: ({commit, getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.deals, {params: params})
                .then(res => {
                    commit('setDealsList', res.data.data);

                    resolve(res.data.data);

                    commit('setDealsLoader', false);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DEALS_LIST_OFFLINE]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.offline, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DEALS_COUNT_OFFLINE]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.countOffline)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DEALS_COUNT]: ({commit, getters}, params) => {

        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.count, {params: params})
                .then(res => {
                    commit('setDealsCount', res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DEAL_SEARCH_SITE]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.sites.search + '/' + params.partner_id + '/' + params.search_text)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DEAL_CPM_SEARCH_SITE]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.sites.search + '/' + params.search_text)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DEAL_STATUS_LIST]: ({commit, getters}) => {
        return axios
            .get(getters.api.deals.statuses)
            .then(res => {
                commit('setDealStatusList', res.data.data);
            })
    },
    [ACTIONS.DEAL_DEPARTMENT_LIST]: ({commit, getters}) => {
        return axios
            .get(getters.api.deals.departmentList)
            .then(res => {
                commit('setDealDepartmentList', res.data.data);
            })
            .catch(err => {
                vue.$catchProceed(err);
            });
    },
    [ACTIONS.DEAL_HISTORY]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.history, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DEAL_VALIDATION]: ({commit}, data) => {
        return new Promise((resolve) => {
            commit('validationDeal', data);
            resolve();
        });
    },
    [ACTIONS.DEAL_FORM_IS_VALID]: ({commit}, form) => {

        var isValidArray = [];

        if (form.status === 'revision') {

            for (var key in form) {
                if (form.hasOwnProperty(key)) {
                    isValidArray.push(form[key] !== null && typeof form[key] !== 'undefined');
                }
            }
        } else {
            delete form.wallet_id;

            for (var i in form) {
                if (form.hasOwnProperty(i)) {
                    isValidArray.push(form[i] !== null);
                }
            }
        }

        const isValid = isValidArray.indexOf(false) === -1;

        commit('setDealFormIsValid', isValid);
    },
    [ACTIONS.DEAL_UPDATE_STATUS]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.deals.updateStatuses + '/' + data.status, {deals: data.deals})
                .then(res => {
                    vue.$notifySuccess({text: 'Deals update'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.CHANGE_PAYMENT_METHOD]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.invoices.changePaymentMethod + '/' + data.deal_id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Invoice update'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    // deal data
    [ACTIONS.CREATE_NEW_SPEND]: ({getters}, data) => {
        return new Promise((resolve, reject) => {

            return axios
                .put(getters.api.deals.massSpend + '/' + data.deal_id, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Added new data'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.EDIT_SPEND]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.deals.spend + '/' + data.id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Update'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DELETE_SPEND]: ({getters}, dealSpendId) => {
        return new Promise((resolve) => {
            axios
                .delete(getters.api.deals.spend + '/' + dealSpendId)
                .then(res => {
                    vue.$notifySuccess({text: 'Deleted'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    // new actions for deal places
    [ACTIONS.CREATE_NEW_DEAL_PLACE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.deals.adPlaces + '/' + data.deal_id + '/' + data.site_id, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.UPDATE_NEW_DEAL_PLACE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.deals.adPlaces + '/' + data.id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Deal place update'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.GET_NEW_DEAL_PLACES]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.adPlaces + '/' + params.deal_id + '/' + params.site_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.CREATE_DEAL_PLACE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.deals.dealPlaces + '/' + data.deal_id, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.UPDATE_DEAL_PLACE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.deals.dealPlaces + '/' + data.id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Deal place update'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.GET_DEAL_PLACES]: ({getters}, deal_id) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.dealPlaces + '/' + deal_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.GET_DEAL_PLACE_INFO]: ({getters}, deal_place_id) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.dealPlaceById + '/' + deal_place_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    // end actions for deal places
    [ACTIONS.DEAL_PLACE_DELETE]: ({getters}, deal_place_id) => {

        return new Promise((resolve) => {
            axios
                .delete(getters.api.deals.dealPlaces + '/' + deal_place_id)
                .then(res => {
                    vue.$notifySuccess({text: 'Deal place deleted'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DEALS_PLACE_TYPES]: ({commit, getters}) => {
        return new Promise((resolve) => {
            axios
                .get(getters.api.deals.placeTypes)
                .then(res => {
                    commit('setPlaceTypes', res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    // end actions for deal place

    // start actions for deal file
    [ACTIONS.DEAL_UPLOAD_FILE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.deals.uploadDealsFile + '/' + data.deal_id, data.file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    vue.$notifySuccess({text: 'File uploaded'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DEAL_DELETE_FILE]: ({getters}, deal_file_id) => {
        return new Promise((resolve) => {
            axios
                .delete(getters.api.deals.deleteDealsFile + '/' + deal_file_id)
                .then(res => {
                    vue.$notifySuccess({text: 'File deleted'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DEAL_FILE_DOWNLOAD]: ({getters}, data) => {
        return new Promise(() => {
            return axios
                .get(getters.api.deals.getFileHash + '/' + data.file_id)
                .then(res => {
                    window.location.href = process.env.VUE_APP_API_URL + res.data.data.link;
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    // end actions for deal file

    [ACTIONS.DEAL_ANALYTICS_CREATE]: ({getters}, params) => {
        return new Promise((resolve, reject) => {
            return axios
                .put(getters.api.deals.buyingStat + '/' + params.dealId, {buyingStats: params.buyingStats})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    // reject(err.response);
                });
        });
    },
    [ACTIONS.DEAL_ANALYTICS_UPLOAD_FILE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.deals.uploadBuyingStatAnalyticFile + '/' + data.deal_buying_stat_analytic_id, data.file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    vue.$notifySuccess({text: 'File uploaded'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DELETE_BUYINGSTAT_FILE]: ({getters}, file_id) => {
        return new Promise((resolve) => {
            axios
                .delete(getters.api.deals.deleteBuyingStatAnalyticFile + '/' + file_id)
                .then(res => {
                    vue.$notifySuccess({text: 'File was delete'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DEAL_ANALYTICS_DELETE]: ({getters}, dealBuyingStatId) => {
        return new Promise((resolve) => {
            axios
                .delete(getters.api.deals.buyingStat + '/' + dealBuyingStatId)
                .then(res => {
                    vue.$notifySuccess({text: 'Deleted'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DEAL_ANALYTICS_EDIT]: ({getters}, params) => {
        return new Promise((resolve) => {
            axios
                .post(getters.api.deals.buyingStat + '/' + params.buyingStats.id, params.buyingStats)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DEAL_ANALYTICS_DELETE_SITE]: ({getters}, params) => {
        return new Promise((resolve) => {
            axios
                .post(getters.api.deals.site_detach + '/' + params.deal_id, params)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DEAL_ANALYTICS_GET]: ({getters}, params) => {
        return new Promise((resolve) => {
            axios
                .get(getters.api.deals.buyingStat + '/' + params.deal_id, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DEAL_ANALYTICS_TO_STORE_DATA_ADD]: ({commit}, filter) => {
        commit('addDealAnalytics', filter);
    },
    [ACTIONS.DEAL_ANALYTICS_STORE_DATA_DELETE]: ({commit}, index) => {
        commit('deleteDealAnalytics', index);
    },
    [ACTIONS.DEAL_ANALYTICS_STORE_DATA_EDIT]: ({commit}, data) => {
        commit('editDealAnalytics', data);
    },
    [ACTIONS.DEAL_ANALYTICS_STORE_DATA_CLEAR]: ({commit}) => {
        commit('clearDealAnalytics');
    },
    [ACTIONS.DEAL_ANALYTICS_DATA_CLEAR]: ({commit}) => {
        commit('dealAnalyticsDataClear', false);
    },
    // end actions for deal analytics

    // start actions for action for deal review validation
    [ACTIONS.DEAL_FORM_VALIDATION]: ({commit}, form) => {
        var isValidArray = [];
        for (var key in form) {
            if (form.hasOwnProperty(key)) {
                isValidArray.push(form[key] !== null);
            }
        }

        const isValid = isValidArray.indexOf(false) === -1;
        commit('setDealFormValidation', isValid);
    },
    [ACTIONS.DEAL_ANALYTICS_VALIDATION]: ({commit}, form) => {

        var isValidArray = [];
        for (var key in form) {
            if (form.hasOwnProperty(key)) {

                isValidArray.push(form[key] !== null);
            }
        }
        const isValid = isValidArray.indexOf(false) === -1;
        commit('setDealAnalyticsValidation', isValid);
    },
    [ACTIONS.DEAL_VALIDATION_REVIEW]: ({commit}, data) => {
        return new Promise((resolve) => {
            commit('reviewValidation', data);
            resolve();
        });
    },
    // end actions for action for deal review validation

    [ACTIONS.GET_SPEND]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.spendData  + '/' + params.deal_id)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DEAL_RESULTS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.results  + '/' + params.deal_id, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DEAL_DATA_CLEAR]: ({commit}) => {
        commit('dealDataClear', false);
    },

    [ACTIONS.MACROS_LIST]: ({getters, commit}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.macroses.macroses)
                .then(res => {
                    commit('setMacrosList', res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.MACROS_CREATE]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .put(getters.api.macroses.macroses, data)
                .then(res => {
                    resolve(res);
                    vue.$notifySuccess({text: 'Macros created'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.MACROS_GET]: ({getters}, macrose_id) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.macroses.macroses + '/' + macrose_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.REDIRECTOR_CREATE]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .put(getters.api.redirectors.redirectors, data)
                .then(res => {
                    resolve(res);
                    vue.$notifySuccess({text: 'Redirector created'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.REDIRECTOR_LIST]: ({getters, commit}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.redirectors.redirectors)
                .then(res => {
                    commit('setRedirectorList', res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);

                });
        });
    },
    [ACTIONS.REDIRECTOR_GET]: ({getters}, redirector_id) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.redirectors.redirectors + '/' + redirector_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_DEAL_EXTENSION]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.extensionTypes)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.GET_DEAL_LABELS]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.labels)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DOWNLOAD_DEALS_XLS]: ({getters}, data) => {
        return axios
            .get(getters.api.deals.xls, {params: data})
            .then(res => {
                window.location.href = res.data.data.link;
            })
            .catch(err => {
                vue.$catchProceed(err);
            });
    },
    [ACTIONS.RE_OPEN_DEAL]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.deals.reOpen + '/' + data.deal_id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Deals update'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.TRANSFER_DEALS]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.deals.transfer, data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);

                    reject(err);
                });
        });
    },
    [ACTIONS.DOWNLOAD_OPERATION_STATS]: ({getters}, data) => {
        return axios
            .post(getters.api.deals.operation_stats, data)
            .then(res => {
                window.location.href = res.data.data.link;
            })
            .catch(err => {
                vue.$catchProceed(err);
            });
    },
    [ACTIONS.SET_INSTALL_COSTS]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.deals.cpi_spend + '/' + data.deal_id, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Success'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.GET_INSTALL_COSTS]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.install_cost + '/' + data.deal_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        })
    },
    [ACTIONS.UPDATE_INSTALL_COSTS]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.deals.install_cost + '/' + data.deal_id, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        })
    },
    [ACTIONS.GET_DEAL_INSTALLS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.install_for_pay + '/' + params.deal_id, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        })
    },
    [ACTIONS.GET_DEAL_SPENDS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.cpi_spend + '/' + params.deal_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        })
    },
    [ACTIONS.EDIT_CPI_SPENDS]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.deals.cpi_spend + '/' + data.cpi_spend_id, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Success'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.SET_PLACE_COST]: ({commit}, data) => {
        commit('setPlaceCost', data);
    },

    [ACTIONS.UPDATE_PLACE_COST]: ({commit}, data) => {
        commit('updatePlaceCost', data);
    },

};

const mutations = {

    setPlaceCost(state, payload) {
        state.paymentsCost[payload.placement_id] = payload.payment_cost.toFixed(2);
        state.placementCost[payload.placement_id] = payload.payment_cost.toFixed(2);
    },

    updatePlaceCost(state, payload) {
        state.placementCost[payload.placement_id] = (state.paymentsCost[payload.placement_id] - payload.place_cost).toFixed(2);
    },
    dealDataClear(state) {
        state.dealPlaces = [];
        state.addDealAnalytics = [
            {
                comment: null,
                devicesScreenshot: null,
                link: null,
                oneMonthAnalyticType: null,
                oneMonthEndDate: null,
                oneMonthScreenshot: null,
                oneMonthStartDate: null,
                places: [],
                threeMonthAnalyticType: null,
                threeMonthEndDate: null,
                threeMonthScreenshot: null,
                threeMonthStartDate: null,
                totalCost: null,
                totalTraffic: null,
                traficGeoScreenshot: null,
                traficScreenshot: null,
            }
        ];
        state.validationDeal = false;
        state.dealFormIsValid = false;
        state.dealFormValidation = false;
        state.dealAnalyticsValidation = false;
        state.reviewValidation = false;
    },
    dealAnalyticsDataClear(state) {
        state.dealAnalyticsValidation = false;
        state.reviewValidation = false;
    },
    setDealsList(state, payload) {
        state.deals.list = payload;
    },
    setPlaceTypes(state, payload) {
        state.deals.placeTypes = payload;
    },
    setDealsCount(state, payload) {
        state.deals.count = payload;
    },
    setDealsLoader(state, payload) {
        state.deals.loader = payload;
    },
    setDealFormIsValid(state, payload) {
        state.dealFormIsValid = payload;
    },
    validationDeal(state, payload) {
        state.validationDeal = payload;
    },
    setDealStatusList(state, payload) {
        state.deals.statusList = payload;
    },
    setDealDepartmentList(state, payload) {
        state.deals.departmentList = payload;
    },
    setDealPlacesCurency(state, payload) {
        state.dealPlacesCurency = payload;
    },
    clearDealAnalytics(state) {
        state.addDealAnalytics = [
            {
                comment: null,
                devicesScreenshot: null,
                link: null,
                oneMonthAnalyticType: null,
                oneMonthEndDate: null,
                oneMonthScreenshot: null,
                oneMonthStartDate: null,
                places: [],
                threeMonthAnalyticType: null,
                threeMonthEndDate: null,
                threeMonthScreenshot: null,
                threeMonthStartDate: null,
                totalCost: null,
                totalTraffic: null,
                traficGeoScreenshot: null,
                traficScreenshot: null,
            }
        ];
    },
    addDealAnalytics(state, payload) {
        state.addDealAnalytics[payload.index] = payload.new_tab;
    },
    editDealAnalytics(state, payload) {
        state.addDealAnalytics[payload.index] = payload.new_value;
    },
    deleteDealAnalytics(state, payload) {
        state.addDealAnalytics[payload] = undefined;
    },
    setInvoiceCount(state, payload) {
        state.invoiceCount = payload;
    },
    setDealFormValidation(state, payload) {
        state.dealFormValidation = payload;
    },
    setDealAnalyticsValidation(state, payload) {
        state.dealAnalyticsValidation = payload;
    },
    reviewValidation(state, payload) {
        state.reviewValidation = payload;
    },
    setMacrosList(state, payload) {
        state.macrosList = payload;
    },
    setRedirectorList(state, payload) {
        state.redirectorList = payload;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
}
