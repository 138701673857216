import Vue from 'vue';
import router from '../../router';
import axios from 'axios';
import * as ACTIONS from '../actions';
import * as EVENTS from "../events";

const vue = new Vue();

const state = {
    unique_link: true,
    leads: {
        list: [],
        count: 0,
        loader: true,
        statusList: [],
        managerList: [],
    },
};

const getters = {
    leadList: state => state.leads.list,
    leadCount: state => state.leads.count,
    leadLoader: state => state.leads.loader,
};

const actions = {
    [ACTIONS.CHECK_URL]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .get(getters.api.leads.checkUrl, {params: data})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err);
                });
        });
    },


    [ACTIONS.LEAD_LIST]: ({commit, getters}, data) => {

        commit('setLeadLoader', true);

        return new Promise((resolve, reject) => {
            return axios
                .get(getters.api.leads.list, {params: data})
                .then(res => {
                    commit('setLeadList', res.data.data);
                    commit('setLeadLoader', false);

                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err);
                });
        });
    },

    [ACTIONS.DOWNLOAD_LEADS_XLS]: ({getters}, params) => {
        return axios
            .get(getters.api.leads.listXls, {params: params})
            .then(() => {
                vue.$notifyInfo({text: 'The link will be sent to you in notifications'});
            })
            .catch(err => {
                vue.$catchProceed(err);
            });
    },

    [ACTIONS.DOWNLOAD_LEADS_STAT_XLS]: ({getters}, params) => {
        return axios
            .get(getters.api.leads.stat_xls, {params: params})
            .then(res => {
                window.location.href = res.data.data.link;
            })
            .catch(err => {
                vue.$catchProceed(err);
            });
    },

    [ACTIONS.LEAD_COUNT]: ({commit, getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.leads.count, {params: params})
                .then(res => {
                    resolve(res.data.data);
                    commit('setLeadCount', res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        })
    },

    [ACTIONS.LEAD_ADD]: ({getters, dispatch}, data) => {
        return new Promise(() => {

            // получаем данные о фильтрах юзера
            const contacts = getters.addedLeadContacts;

            // проверяем, что указан хотя бы один контакт
            var field_require = 0;

            // перебираем карточки контактов
            for (var key in contacts) {

                if (contacts.hasOwnProperty(key)) {

                    // перебираем поля в карточке
                    for (var fieldKey in contacts[key]) {

                        if (contacts[key].hasOwnProperty(fieldKey)) {

                            if (fieldKey !== 'title' && fieldKey !== 'full_name') {
                                let field = contacts[key][fieldKey];

                                if (typeof field === 'string') {
                                    field = field.trim();
                                }

                                if (field !== null && field !== '') {
                                    field_require++;
                                }
                            }
                        }
                    }
                }
            }

            if (field_require === 0) {
                vue.$eventEmit(EVENTS.DISABLED_LOADER);
                vue.$notifyError({text: 'Empty required'});
                return;
            }

            // создаем лид
            axios
                .post(getters.api.leads.createLead, data)
                .then(res => {

                    // при успешном создании - добавляем фильтры
                    const leadId = res.data.data.id;

                    // добавляем контакты в базу
                    dispatch(ACTIONS.LEAD_CONTACTS_CREATE, {leadId: leadId, contacts: contacts})
                        .then(() => {
                            vue.$notifySuccess({text: 'Lead successfully created'});

                            // TODo протестити
                            if (data.target === 'continue') {
                                location.reload();
                            } else {
                                if (getters.role === 'control') {
                                    router.push('/control/list')
                                } else {
                                    router.push('/leads/list')
                                }
                            }

                            // if (getters.role === 'control') {
                            //     router.push('/control/list');
                            // } else if (getters.role === 'searcher') {
                            //     router.push('/leads/add');
                            // } else {
                            //     router.push('/leads/list');
                            // }
                        });
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.LEAD_GET]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.leads.findById, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    // если ошибка, например, нет доступа к лиду - редиректим на список лидов
                    vue.$catchProceed(err);
                    router.push('/');
                });
        });
    },

    [ACTIONS.CHECK_ACCESS_TO_LEAD]: ({getters}, params) => {
        return new Promise((resolve, reject) => {
            return axios
                .get(getters.api.leads.findById, {params: params})
                .then(() => {
                    resolve(true);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(false)
                });
        });
    },

    [ACTIONS.LEAD_EDIT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.leads.editLead, data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.LEAD_EDIT_STATUS]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.leads.editLeadStatus, data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err);
                });
        });
    },

    [ACTIONS.LEAD_MASS_ACTION]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.leads.massAction, data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);

                    reject(err);
                });
        });
    },

    [ACTIONS.LEAD_HISTORY_GET]: ({getters}, params) => {
        return new Promise((resolve, reject) => {
            return axios
                .get(getters.api.logs.getLeadHistory, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })

                .catch(err => {
                    vue.$catchProceed(err);

                    reject(err);
                });
        });
    },

    [ACTIONS.LEADS_UPLOAD_FILE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.leads.upload, data.file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    vue.$notifySuccess({text: res.data.data.message});
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },


    // actions for attach leads
    [ACTIONS.SEARCH_LEAD]: ({getters}, params) => {
        return new Promise((resolve, reject) => {
            return axios
                .get(getters.api.leads.searchForDeals, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err);
                });
        });
    },

    [ACTIONS.ATTACH_LEAD_FOR_DEAL]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.deals.attach_lead + '/' + data.deal_id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Lead attached'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.CREATE_NEW_LEAD]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.leads.createLead, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Lead added'});
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_LG_REPORT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.leads.report, {params: data})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_LEADS_STAT]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.leads.chart, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.CHECK_LEADS_URL]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.leads.checkUrls, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
};

const mutations = {
    setUniqueLink(state, payload) {
        state.unique_link = payload;
    },
    setLeadList(state, payload) {
        state.leads.list = payload;
    },
    setLeadCount(state, payload) {
        state.leads.count = payload;
    },
    setLeadLoader(state, payload) {
        state.leads.loader = payload;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
}
