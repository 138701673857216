import Vue from 'vue';
import axios from 'axios';

import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {};

const getters = {};

const actions = {

    [ACTIONS.GET_FINCONTROL_LIST]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.settings.fincontrolList)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_FINCONTROL_RESPONSIBLE]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.settings.fincontrolResponsibleId)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.UPDATE_FINCONTROL_LIST]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.settings.updateFincontrolResponsible, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Update'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
}
