import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';
const vue = new Vue();

const state = {
    manuals: null,
};

const getters = {
    user_manuals: state => state.manuals,
};

const actions = {

    [ACTIONS.GET_MANUALS]: ({commit, getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.manuals.manuals)
                .then(res => {

                    commit('setManuals', res.data.data);

                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_MANUAL]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.manuals.manuals + '/' + data.id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

};

const mutations = {
    setManuals(state, payload) {

        let modify_manuals = new Map();

        for (const stateKey of payload) {
            for (const stateKeyElement in stateKey) {
                if (stateKey.hasOwnProperty(stateKeyElement)) {
                    modify_manuals.set(stateKeyElement, stateKey[stateKeyElement]);
                }
            }
        }
        state.manuals = modify_manuals;
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
